// uses jQuery
var PeopleMedia = PeopleMedia || {};
PeopleMedia.Consent = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Consent";

    var _consentAccepted = false;
    var _personalizedConsent = false;
    var _rejectAllClicked = false;
    var _acceptAllClicked = false;
    var _acceptClicked = false;
    var _personalizeConfirmChoicesClicked = false;
    var _loggedModal = false;

    var $sdk = $jq();
    var sdkInterval = null;
    var sdkIntervalMs = 250;
    var sdkIntervalCount = 0;
    var sdkIntervalMax = 20;        // try for 5 seconds after DOM ready then give up if still not found

    var refreshAfterConsent = function () {
        if (typeof (PeopleMediaConfig) !== "undefined" &&
            PeopleMediaConfig !== null &&
            typeof (PeopleMediaConfig.Configuration) !== "undefined" &&
            PeopleMediaConfig.Configuration !== null) {
            return PeopleMediaConfig.Configuration.refreshAfterConsent;
        }
        return false;
    };

    var getConsentAccepted = function () {
        if (_consentAccepted) {
            return true;
        }
        var optanonAlertBoxClosedCookie = PeopleMedia.Cookies.getCookie("OptanonAlertBoxClosed");
        if (optanonAlertBoxClosedCookie) {
            return true;
        }
        return false;
    };

    var parseConsentGroups = function (str) {
        var map = [null, "StrictlyNecessary", "Performance", "Functional", "Advertising"];
        var result = {};
        var split = (str) ? str.split(",") : [];
        for (var i = 0; i < split.length; i++) {
            var kvp = (split[i]) ? split[i].split(":") : [];
            if (kvp.length === 2 && kvp[0] && (kvp[1] === "1" || kvp[1] === "0")) {
                var keyIndex = parseInt(kvp[0], 10);
                if (!isNaN(keyIndex) && keyIndex > 0 && keyIndex < map.length) {
                    var key = map[keyIndex];
                    if (key) {
                        result[key] = (kvp[1] === "1");
                    }
                }
            }
        }
        return result;
    };

    var getConsentGroups = function () {
        var optanonConsentCookie = PeopleMedia.Cookies.getCookie("OptanonConsent");
        var consent = (optanonConsentCookie) ? PeopleMedia.Cookies.parseKeyValueString(optanonConsentCookie) : null;
        var groupString = (consent && consent.groups) ? consent.groups : "";
        return parseConsentGroups(groupString);
    };

    var getPageId = function () {
        return typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
    };

    var isEventValid = function(value) {
        return typeof (value) === "object" && value.eventName;
    };

    var trackConsentEvents = function (params) {
        try {
            // console.log("Calling /v3/ajax/TrackConsentEvents");
            var validEvents = [];
            if (params && params.events && Array.isArray(params.events)) {
                Array.prototype.push.apply(validEvents, params.events.filter(isEventValid));
            }
            if (validEvents.length === 0 || !Array.isArray(validEvents)) {
                // do nothing if no events
                trackConsentEventsComplete(null, { status: "Success" }, params);
                return;
            }

            var pageDefinitionId = getPageId();
            var url = "/v3/ajax/TrackConsentEvents";
            var startTime = new Date().getTime();
            var timeoutValue = 3000;

            var ajaxData = {
                "Events": validEvents,
                "PageDefinitionId": pageDefinitionId
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    trackConsentEventsComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "trackConsentEvent", duration: duration }, jqXHR, textStatus, errorThrown);
                    trackConsentEventsComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "trackConsentEvent" });
            trackConsentEventsComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };

    var trackConsentEventsComplete = function (err, response, params) {
        if (params && typeof (params.onComplete) === "function") {
            params.onComplete(err, response, params);
        }
    };

    // Track single event with callback
    var trackConsentEvent = function (eventName, eventValue, callback) {
        // make tracking call asynchronously
        setTimeout(function() {
            trackConsentEvents({
                events: [
                    {
                        eventName: eventName,
                        eventValue: eventValue
                    }
                ],
                onComplete: callback
            });
        }, 250);
    };

    var trackConsentChanged = function (params) {
        try {
            // console.log("In trackConsentChanged");
            var trackedEvents = [];
            _consentAccepted = true;
            var pageId = getPageId();
            var onComplete = (params && params.onComplete) ? params.onComplete : null;
            var alertBoxClosed = (params && params.data && params.data.alertBoxClosed) ? params.data.alertBoxClosed : null;
            var consent = (params && params.data && params.data.consent) ? PeopleMedia.Cookies.parseKeyValueString(params.data.consent) : null;
            var groupString = (consent && consent.groups) ? consent.groups : null;
            var groupParsed = parseConsentGroups(groupString);
            var eventValue = {
                alertBoxClosed: alertBoxClosed,
                groups: groupParsed,
                shouldRefresh: false
            };
            if (groupString) {
                eventValue.groupString = groupString;
            }
            if (_acceptClicked) {
                eventValue.accept = true;
            }
            if (_personalizedConsent) {
                eventValue.personalized = true;
            }
            if (_rejectAllClicked) {
                eventValue.rejectAll = true;
            }
            if (_acceptAllClicked) {
                eventValue.acceptAll = true;
            }
            if (_personalizeConfirmChoicesClicked) {
                eventValue.personalizedConfirmChoices = true;
            }
            if (pageId) {
                eventValue.pageId = pageId;
            }
            if (refreshAfterConsent() && typeof (eventValue.groups) === "object" && eventValue.groups.Advertising) {
                eventValue.shouldRefresh = true;
            }
            trackedEvents.push({
                eventName: "ConsentChanged",
                eventValue: JSON.stringify(eventValue)
            });
            trackConsentEvents({ events: trackedEvents, groups: groupParsed, shouldRefresh: eventValue.shouldRefresh, onComplete: onComplete });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "trackConsentChanged" });
        }
    };

    var onConsentChanged = PeopleMedia.Utility.debounce(function (e) {
        var data = (e && e.detail) ? e.detail : null;
        trackConsentChanged({
            data: data,
            onComplete: function (err, response, params) {
                // console.log("Finished onConsentChanged");
                // force page refresh for server side pixels
                if (!err && params && params.shouldRefresh) {
                    window.location.reload();
                }
            }
        });
    }, 1000, true);

    var modalVisible = function () {
        return ($sdk.length > 0 &&
            $sdk.css("display") !== "none" &&
            $sdk.css("visibility") !== "hidden" &&
            $sdk.css("opacity") !== "0");
    };

    var detectBannerDisplay = function () {
        // We don't get a notification when the banner is shown
        try {
            sdkInterval = setInterval(function () {
                sdkIntervalCount++;
                $sdk = $jq("#onetrust-consent-sdk");
                if ($sdk.length > 0 || sdkIntervalCount >= sdkIntervalMax || getConsentAccepted()) {
                    clearInterval(sdkInterval);
                    sdkInterval = null;
                    if (!_loggedModal && !getConsentAccepted() && $sdk.length > 0 && modalVisible($sdk)) {
                        _loggedModal = true;
                        trackConsentEvent("ConsentShow", "");
                    }
                }
            }, sdkIntervalMs);
        } catch (ex) {
            console.log(ex);
            clearInterval(sdkInterval);
            sdkInterval = null;
        }
    };

    var onConsentPersonalizeTapped = PeopleMedia.Utility.debounce(function (e) {
        _personalizedConsent = true;
        trackConsentEvent("ConsentPersonalizeTapped", "");
    }, 1000, true);

    var onConsentMainAcceptTapped = PeopleMedia.Utility.debounce(function (e) {
        _acceptClicked = true;
        trackConsentEvent("ConsentMainAcceptTapped", "");
    }, 1000, true);

    var onConsentMainAllowAllTapped = PeopleMedia.Utility.debounce(function (e) {
        _acceptAllClicked = true;
        trackConsentEvent("ConsentMainAllowAllTapped", "");
    }, 1000, true);

    var onConsentMainRefuseAllTapped = PeopleMedia.Utility.debounce(function (e) {
        _rejectAllClicked = true;
        trackConsentEvent("ConsentMainRefuseAllTapped", "");
    }, 1000, true);

    var onConsentPersonalizeConfirmChoicesTapped = PeopleMedia.Utility.debounce(function (e) {
        _personalizeConfirmChoicesClicked = true;
        trackConsentEvent("ConsentPersonalizeConfirmChoicesTapped", "");
    }, 1000, true);

    var initialize = function () {
        // triggered from script executed in OptanonWrapper
        document.addEventListener("ConsentChanged", onConsentChanged);

        // bind as delegated events outside ready handler
        // "Personalize my choices" button
        $jq(document).on("mousedown", "#onetrust-pc-btn-handler", onConsentPersonalizeTapped);

        // "Accept" button
        $jq(document).on("mousedown", "#onetrust-accept-btn-handler", onConsentMainAcceptTapped);

        // "Allow All" button
        $jq(document).on("mousedown", "#accept-recommended-btn-handler", onConsentMainAllowAllTapped);

        // "Reject All" button
        $jq(document).on("mousedown", ".ot-pc-refuse-all-handler", onConsentMainRefuseAllTapped);

        // "Confirm My Choices" button
        $jq(document).on("mousedown", ".save-preference-btn-handler", onConsentPersonalizeConfirmChoicesTapped);

        $jq(function () {
            detectBannerDisplay();
        });
    };

    return {
        get consentAccepted() { return getConsentAccepted(); },
        get consentGroups() { return getConsentGroups(); },
        initialize: initialize
    };
})(jQuery);

PeopleMedia.Consent.initialize();
